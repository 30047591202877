@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;400;700&display=swap');


:root {
  --h-f-c-color: #D1D1BB;
  --bg-color: #969696;
  --text-color: black;
  --accent: #011627;
  --shadow: black;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: 'Mukta', sans-serif;
  font-weight: 400;
  font-size: 19.2px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.placeholder-img {
  border: 1px solid black;
  margin: auto;
  text-align: center;
  background-color: var(--h-f-c-color);
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}


.toast-animation {
  animation-fill-mode: both;
}

.toast-slide-in {
  animation-name: slideInRight;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
}



.nav-select {
  margin: auto;
}

.header-width {
  max-width: 1200px;
}

.active {
  text-decoration: underline;
}

.nav {
  color: var(--text-color);
  background-color: var(--h-f-c-color);
  border-bottom: solid black 1px;
}

.contact-card {
  margin: auto;
  height: 90px;
  box-shadow: 1px 1px 5px rgba(16, 16, 16, 0.5)
}

.service-card-button {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
}

.content-container {
  margin: 15px auto;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border: 2px solid black;
  background-color: var(--h-f-c-color);
  border-radius: 8px;
  overflow: hidden;
}

.content-container img {
  margin: 5px;
}

.carousel-container {
  margin: 15px auto;
  margin-bottom: 80px;
  border: 2px solid black;
  background-color: var(--h-f-c-color);
  border-radius: 8px;
  width: 90vw;
  overflow: hidden;
}

.heading-container {
  margin-bottom: 12px;
}

.heading-text {
  color: var(--accent);
  text-transform: uppercase;
  font-size: 3.5em;
  font-weight: 800;
  text-shadow: 1px 1px 0px var(--bg-color),
    1px 2px 0px var(--bg-color),
    1px 3px 0px var(--bg-color),
    1px 4px 0px var(--bg-color),
    1px 5px 0px var(--bg-color),
    1px 6px 0px var(--bg-color),
    1px 10px 5px rgba(16, 16, 16, 0.5),
    1px 25px 50px rgba(16, 16, 16, 0.2);
}


.subheading-container {
  margin-bottom: 16px;
}


.subheading-text {
  font-size: 2em;
  font-weight: 600;
  color: var(--accent);
  text-decoration: underline;
}

.footer-quote {
  font-style: italic;
}

.abt-us-img{
  width: 14em;
  object-fit: contain;
  border-radius: 4px
}

@media(max-width: 370px){
  .abt-us-img{
    width: 10em;
    object-fit: contain;
    border-radius: 4px
  }
}

.textbox {
  width: 70%;
  margin: auto;
  white-space: pre-wrap;
}

.textbox-about-us {
  width: 70%;
  margin: auto;
  white-space: pre-wrap;
  text-align: left;
}

.card-textbox {
  margin: auto;
  white-space: pre-wrap;
}

.footer {
  background-color: var(--h-f-c-color);
  border-top: solid black 1px;
}

.toast-closer {
  margin: 0 0 0.1rem 0;
}

@media (max-width: 700px) {
  .contact-card {
    display: none;
  }
}


@media (max-width: 991px) {
  .header-hide-responsive {
    display: none;
  }
}



@media (max-width: 1140px) {
  .service-card {
    width: 38rem;
    margin: 8px auto;
    text-align: left;
    height: 26rem;
    box-shadow: 1px 1px 5px rgba(16, 16, 16);
    transition: box-shadow 2s ease;
    font-size: 0.6em;
  }
}

@media (max-width: 1140px) {
  .service-card-highlight {
    width: 38rem;
    margin: 8px auto;
    text-align: left;
    height: 26rem;
    box-shadow: 0 0 10px 5px rgba(0, 0, 255, 0.8);
    font-size: 0.6em;
  }
}


.service-card {
  width: 38rem;
  margin: 8px auto;
  text-align: left;
  height: 26rem;
  box-shadow: 1px 1px 5px rgba(16, 16, 16);
  transition: box-shadow 2s ease;
}

.service-card-image {
  border-radius: 12px;
  margin: auto;
  width: 100%;
  height: 20rem;
  object-fit: cover;
}

.service-card-highlight {
  width: 38rem;
  margin: 8px auto;
  text-align: left;
  height: 26rem;
  box-shadow: 0 0 10px 5px rgba(13,110,253,255);
}

.contact-page-cards {
  box-shadow: 1px 1px 5px rgba(16, 16, 16, 0.5);
  width: 56rem;
  margin: auto;
  height: 58rem;
}

.horizontal-contact-form {
  box-shadow: 1px 1px 5px rgba(16, 16, 16, 0.5);
  width: 56rem;
  margin: auto;
  height: 30rem;
}

.contact-page-font {
  font-size: 1.8em;

}

@media(max-width: 1025px) {
  .contact-page-font {
    font-size: 1.4em;
  }
}

@media (max-width: 1000px) {
  .contact-page-font {
    font-size: 1em;
  }
}

.contact-card-title {
  font-size: 1.3em;
  font-weight: bold;
  margin-bottom: -15px;
}

@media (max-width: 780px) {
  .contact-info-font {
    font-size: 1.2em;
  }
}

.contact-box {
  width: auto;
  box-shadow: 1px 1px 5px rgba(16, 16, 16, 0.5);
  border: 1px solid black;
}

.homepage-contact-cards {
  box-shadow: 1px 1px 5px rgba(16, 16, 16, 0.5);
  width: 34rem;
  margin: auto;
  height: 58rem;
}

.home-img{
  width: 95%;
  border-radius: 4px;
  box-shadow: 1px 1px 5px rgba(16, 16, 16, 0.5);
  margin: auto;
}

@media (max-width: 780px) {
  .homepage-contact-cards {
    border: 1px solid black;
    margin: auto;
    width: 20rem;
    font-size: 0.8em;
    margin-bottom: 12px;
    height: 57rem;
  }
}

@media (max-width: 425px) {
  .footer-text-size {
    font-size: 0.8em;
  }
}

@media (max-width: 321px) {
  .heading-text {
    font-size: 3em;
  }
}

.heading-text-2 {
  color: var(--accent);
  text-transform: uppercase;
  font-size: 3.5em;
  font-weight: 800;
  text-shadow: 1px 1px 0px var(--bg-color),
    1px 2px 0px var(--bg-color),
    1px 3px 0px var(--bg-color),
    1px 4px 0px var(--bg-color),
    1px 5px 0px var(--bg-color),
    1px 6px 0px var(--bg-color),
    1px 10px 5px rgba(16, 16, 16, 0.5),
    1px 25px 50px rgba(16, 16, 16, 0.2);
}

@media (max-width: 521px) {
  .heading-text-2 {
    font-size: 2.4em;
  }
}

.testimonial-card {
  box-shadow: 1px 1px 5px rgba(16, 16, 16, 0.5);
  border: 1px solid black;
  width: 20em;
  margin: auto;
}

@media (max-width: 425px) {
  .testimonial-card {
    box-shadow: 1px 1px 5px rgba(16, 16, 16, 0.5);
    border: 1px solid black;
    width: auto;
    margin: auto;
  }
}

.star-component {
  width: auto;
  height: 30px;
  margin-left: -20px;
}
.star-image {
  width: 22px;
  height: 22px;
}

.testimonial-card-footer {
  margin-bottom: 0.5em;
}
.smaller-text {
  font-size: 1em;
}

.gallery-item {
  width: 20%;
  max-width: 450px;
}

.home-subheading-text{
  font-size: 1.8em;
  font-weight: 400;
  text-decoration: underline;  
}


