:root {
    --photo-margins: 15px;
    --photo-margins-small: 10px;
}

.gallery-job-slice {
    width: 375px;
    min-height: fit-content;
    border: 1px solid black;
    box-shadow: 1px 1px 5px rgba(16, 16, 16, 0.5);
    border-radius: 10px;
    margin: 10px;
}


.title {
    font-weight: bold;
    align-self: flex-start;
    font-size: 1.1em;
}

.gallery-card {
    margin: 0px auto;
    margin-left: 0px;
    margin-right: 0px;
    padding: 0px;
}

.title-location {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 0.9em;
    font-style: italic;
}

.title-date {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 0.9em;
}

.text-border {
    color: black;
}

.badge-col {
    display: flex;
    justify-content: flex-end;
}

.location {
    align-self: flex-start;
}


.date {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}


.gallery-image-wrapper-landscape {
    margin: auto;
    margin-bottom: var(--photo-margins);
    margin-top: var(--photo-margins);
    width: 325px;
    height: 244px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.gallery-image-wrapper-portrait {
    margin: auto;
    margin-bottom: var(--photo-margins);
    margin-top: var(--photo-margins);
    width: 325px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}



.gallery-image-wrapper-landscape img {
    margin: 0;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 15px;
    border: 1px solid black;
}

.gallery-image-wrapper-portrait img {
    margin: 0;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 15px;
    border: 1px solid black;
}


@media (max-width: 425px) {
    .gallery-image-wrapper-landscape {
        margin: auto;
        margin-bottom: 0;
        width: 90%;
        height: 200px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
    }
}

@media (max-width: 425px) {
    .gallery-image-wrapper-portrait {
        margin: auto;
        margin-bottom: var(--photo-margins-small);
        margin-top: var(--photo-margins-small);
        width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
    }
}

@media (max-width: 425px) {
    .gallery-image-wrapper-landscape img {
        margin: 0;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        border-radius: 15px;
        border: 1px solid black;
    }
}

@media (max-width: 425px) {
    .gallery-image-wrapper-portrait img {
        margin: 0;
        margin-bottom: 10px;
        max-width: 90%;
        max-height: 100%;
        object-fit: contain;
        border-radius: 15px;
        border: 1px solid black;
    }
}


@media (max-width: 425px) {
    .gallery-job-slice {
        width: 270px;
        margin: 10px auto;
        min-height: fit-content;
        border: 1px solid black;
        box-shadow: 1px 1px 5px rgba(16, 16, 16, 0.5);
        border-radius: 10px;
    }
}



.selected-image {
    width: 100%;
    max-height: 80vh;
    object-fit: contain;

}




.modal-content {
    background-color: transparent;
    border: none;
}

.modal-dialog {
    max-width: 100vw;
    margin: 0;
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-backdrop {
    opacity: 0.7;
}

.modal-header {
    color: #fff;
}


.my-masonry-grid {
    display: flex;
    margin: auto;
    width: auto;
}

.my-masonry-grid_column {
    background-clip: padding-box;
}

/* .my-masonry-grid_column>div {
    margin-bottom: 10px;
} */


.testimonial-modal-dialog {
    width: 20em;
    margin: auto;
}

.img-modal-dialog {
    width: fit-content;
    margin: auto;
}

.clickable {
    cursor: pointer;
}

.unclickable {
    cursor: default;
}

